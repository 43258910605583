import React, { useEffect, useState } from "react";
import { Menu, message, Table, Typography } from "antd";
import Axios from "axios";
import "./AboutPage.css";
import Heading from "../SectionHeading/Heading";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AboutPage = () => {
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState("1");
  const [apiVersion, setApiVersion] = useState("");

  const [version, setVersion] = useState("1.0.695");

  const [apiLoad, setApiLoad] = useState(false);
  const [pkiLoad, setPkiLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageDataLength = 10;
  const [desirePageData, setDesirePageData] = useState({
    PageNumber: "1",
    PageSize: pageDataLength,
  });
  const [pkiData, setPkiData] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (tab === "userguide") {
      setSelectedMenu("3");
    }
  }, [location.search]);

  const handleMenuClick = ({ key }) => {
    setSelectedMenu(key);
  };

  useEffect(() => {
    setApiLoad(true);
    setPkiLoad(true);

    let apiVersion = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.API_VERSION,
    };
    Axios.request(apiVersion)
      .then((response) => {
        setApiVersion(response?.data?.version);
        setApiLoad(false);
      })
      .catch((error) => {
        console.log(error);
      });
    const fetchData = async () => {
      try {
        const response = await Axios.get(apiEndPoints.FETCH_PKI);
        setPkiData(response.data);
        setPkiLoad(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const downloadJSONFile = () => {
    const blob = new Blob([JSON.stringify(pkiData, null, 2)], {
      type: "application/json",
    });
    saveAs(blob, "edge-config.json");
  };

  return (
    <div className="about-page-container">
      <div className="about-menu-container">
        <Menu
          mode="inline"
          selectedKeys={[selectedMenu]}
          onClick={handleMenuClick}
          className="about-menu"
        >
          <Menu.Item key="1" className="menuItem">
            About
          </Menu.Item>
          <Menu.Item key="2" className="menuItem">
            PKI configuration
          </Menu.Item>
          <Menu.Item key="3" className="menuItem">
            User guide
          </Menu.Item>
        </Menu>
      </div>
      <div className="about-content-container">
        <div className="about-content">
          <span className="heading-container">
            <Heading
              text={selectedMenu === "3" ? "" : `Edgenius Management Portal`}
            />
            {apiLoad && selectedMenu === "1" ? <SpinnerLoader /> : ""}
            {pkiLoad && selectedMenu === "2" ? <SpinnerLoader /> : ""}
          </span>

          {selectedMenu === "1" && (
            <div>
              {!apiLoad && (
                <div
                  className="Description_about"
                  style={{ fontFamily: "ABBVoice", fontWeight: "100" }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      marginTop: "20px",
                    }}
                  >
                    Version
                  </span>
                  <br />
                  <div
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "5px",
                      marginTop: "10px",
                    }}
                  >
                    <span> edge-management-console:{version} </span>

                    <span>edge-management-console-api:{apiVersion} </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedMenu === "2" && (
            <div className="pki-content-container">
              <div className="pki-json-container" style={{ width: "80%" }}>
                {Object.entries(pkiData).map(([key, value]) => (
                  <div
                    key={key}
                    className="key-value-item"
                    style={{ fontFamily: "ABBVoice", fontSize: "16px " }}
                  >
                    <span className="pki-key">{key} : </span>{" "}
                    <span className="pki-value"> {value}</span>
                  </div>
                ))}
              </div>
              <div>
                <button className="download-button" onClick={downloadJSONFile}>
                  Download as a file
                </button>
              </div>
            </div>
          )}

          {selectedMenu === "3" && (
            <div
              className="Description_about"
              style={{
                fontFamily: "ABBVoice",
                paddingTop: "10px",
                fontSize: "16px",
              }}
            >
              <p>
                Please visit the following links for a detailed user
                documentation:
              </p>
              <ul>
                <li>
                  Edge Installation Guide:{" "}
                  <a
                    className="Hyper_link"
                    href="https://internal.iiot.automation.abb.com/docs/edgenius/install/CentrallyManageEdge/centrallyManagedEdge"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Centrally Managed Edge | IIoT Information Portal (ABB)
                  </a>
                </li>
                <li>
                  Edge Management Portal (EMP):{" "}
                  <a
                    className="Hyper_link"
                    href="https://internal.iiot.automation.abb.com/docs/edgenius/management-tools/edgenius-management-console/management-portal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ABB Edgenius Management Portal Overview | IIoT Information
                    Portal
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
